<template>
  <div>
    <div class="bg-white p-4 my-4 rounded-lg shadow-sm">
      <label>Discount</label>
      <h4 class="text-2xl">All Discounts</h4>
    </div>
    <div class="fle-col bg-white p-4 my-4 shadow-sm rounded-lg">
      <div class="bg-white  rounded-lg flex justify-between">
      <div class="relative">
        <svg
          class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          width="20"
          height="20"
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2a8 8 0 016.32 12.906l4.387 4.387a1 1 0 01-1.415 1.415l-4.387-4.387A8 8 0 1110 2zm0 2a6 6 0 100 12 6 6 0 000-12z"
          />
        </svg>
        <input
          type="text"
          v-model="filter"
          placeholder="Search Discount Name"
          class="rounded-lg bg-gray-50 w-96 border pl-10 py-2"
          @keyup.enter="searchByValidTo"
        />
        <button
    v-if="filter"
    class="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
    @click="actionReset"
    title="Clear search"
  >
    ✕
  </button>
        <span v-if="typing">
          <em>Mencari ..</em>
        </span>
      </div>
      <div class="flex justify-end">
        <button class="bg-blue-700 rounded-lg h-10 mr-3 text-white px-4 flex items-center justify-between">
          <router-link
            class="flex justify-between items-center"
            :to="{ path: `/discount-add` }"
          >
          <span>
            <svg class="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5"/>
          </svg>
          </span>
          <span class="text-white">
            Add New
          </span>
          </router-link>
        </button>
        <t-dropdown>
        <div
          slot="trigger"
          slot-scope="{ mousedownHandler, focusHandler, blurHandler, keydownHandler }"
          class="flex"
        >
          <button
            class="flex items-center justify-center h-10 px-3 py-2.5 text-gray-500 bg-white border border-gray-400 rounded-lg"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <svg
              class="w-4 h-4 mr-1 text-gray-600 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M5.05 3C3.291 3 2.352 5.024 3.51 6.317l5.422 6.059v4.874c0 .472.227.917.613 1.2l3.069 2.25c1.01.742 2.454.036 2.454-1.2v-7.124l5.422-6.059C21.647 5.024 20.708 3 18.95 3H5.05Z" />
            </svg>
            Filter
            <svg
              class="w-5 h-5 ml-1 text-gray-600 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                points="9.29289322 12.9497475 10 13.6568542 15.6568542 8 14.2426407 6.58578644 10 10.8284271 5.75735931 6.58578644 4.34314575 8"
              />
            </svg>
          </button>
        </div>
        <div class="w-custom bg-white absolute -right-2 pt-2 pb-3 border rounded-lg" slot-scope="{ hide, blurHandler }">
          <div class="block w-full px-4 py-2 text-sm text-gray-500 transition duration-150 ease-in-out focus:outline-none focus:bg-gray-100" role="menuitem">
            <div class="flex justify-between mb-3 mt-2">
              <div>Filter</div>
              <div @click="hide">
                <svg   class="w-4 h-4 text-gray-800 dark:text-white cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
              </svg>
              </div>
            </div>
            <div class="grid gap-4 grid-cols-2">
              <div>
              
                <label class="text-gray-600 font-semibold mb-3">Status</label>
                <StatusFilterComponent 
                  v-model="discountStatus"
                  :options="discountStatusOption"
                />
               </div>
               <div>
                <label class="text-gray-600 font-semibold mb-3">Discount Type</label>
                <TypeFilterComponent
                  v-model="discountType"
                  :options="discountTypeOption"
                />
                <!-- <t-select id="currency" class="mb-2" v-model="discountType" :options="discountTypeOption" required @blur="blurHandler"></t-select> -->
               </div>
               <div>
                <DatetimeCustom :timeDate="false" :label="'Start Date'" v-model="start_date" @blur="blurHandler"></DatetimeCustom>
               </div>
               <div>
                <DatetimeCustom :timeDate="false" :label="'Start Date'" v-model="end_date" @blur="blurHandler"></DatetimeCustom>
               </div>
           
            </div>
            <div class="mt-3">
              <button class="border bg-blue-500 px-4 py-2 text-white rounded-lg" @click="searchByValidTo" @blur="blurHandler">
                Filter
              </button>
              <button class="border bg-white text-gray-500 px-4 py-2 rounded-lg ml-2" @click="asyncClearFind() " @blur="blurHandler">Reset</button>
            </div>
          </div>
        </div>
      </t-dropdown>
      </div>
    </div>
    <div class="mt-4">
      <TableDiscounts
          :currentpage="currentPage"
          :perpage="perPage"
          :discounts="discounts"
          :isLoading="isLoading"
          :Deactivate="Deactivate"
          :Reactivate="Reactivate"
        />
        
        <div v-if="totalRows / perPage > 1" class="flex justify-between ml-1 items-center mt-3">
          <div class="font-semibold">
          <span class="text-gray-500 font-normal">Showing </span> {{ currentPage }}-{{ perPage }} <span
            class="text-gray-500 font-normal">of</span> {{ parseInt(totalRows) }}
        </div>
        <div class="mb-2">
          <t-pagination 
          :total-items="Number(totalRows)" 
          :per-page="perPage" 
          v-model="currentPage"
          class="rounded-lg"
          />
        </div>
      </div>
    </div>
    </div>
</div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import TableDiscounts from '../../components/discount/discounts-offer.vue';
import constant from '../../store/constant';
import DatetimeCustom from '../../components/form/Datetime.vue'
import Utils from '../../plugins/util';
import StatusFilterComponent from '../../components/form/StatusFilterComponent.vue';
import TypeFilterComponent from '../../components/form/StatusFilterComponent.vue';
const DISCOUNT_TYPES = Object.entries(constant.DISCOUNT_TYPES).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const discountStatuses = [
  {
    text: 'Currently Active',
    value: 1,
  },
  {
    text: 'Not Yet Active',
    value: 2,
  },
  {
    text: 'Inactive',
    value: 3,
  },
]
export default {
  name: 'discount',
  components: {
    TableDiscounts,
    DatetimeCustom,
    StatusFilterComponent,
    TypeFilterComponent
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      currentFrom: null,
      currentTo: null,
      filter: null,
      isLoadingSearch: false,
      isLoadingBtnSearch: false,
      debounce: null,
      start_date:null,
      end_date: null,
      range: {
        // start: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        // end: moment().format('YYYY-MM-DD'),
        start: null,
        end: null,
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      typing: false,
      discountTypeOption: [...DISCOUNT_TYPES],
      discountType: null,
      discountStatusOption: [...discountStatuses],
    
      discountStatus: null,
    };
  },
  watch: {
    currentPage: function() {
      this.actionGetDiscounts();
    },

    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Discounts | Content Management System Ebooks Gramedia.com';
      },
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.discounts.isLoading,
      isError: (state) => state.discounts.isError,
      totalRows: (state) => state.discounts.totalRows,
      discounts: (state) => state.discounts.items,
      errorMessage: (state) => state.discounts.errorMessage,
    }),
  },
  mounted() {
    this.actionGetDiscounts();
  },
  methods: {
    ...mapActions('discounts', [
      'searchDiscounts',
      'fetchDiscountsByid',
      'fetchDiscounts',
      'searchDiscountsBydate',
      'reactiveDiscount',
      'deactiveDiscount',
    ]),
    actionRefresh() {
      this.actionGetDiscounts();
    },
    actionReset() {
      window.location.reload()
    },
    actionRefreshData() {
      if (this.filter.length == 0) {
        this.actionRefresh();
      }
    },
    actionGetDiscounts() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if(this.filter) payload.search = Utils.encodeUrlWithBase64(this.filter);
      if(this.discountStatus) payload.discountStatus = this.discountStatus;
      if(this.discountType) payload.discountType = this.discountType;
      if(this.range?.start) payload.from = moment(this.range?.start).format('YYYY-MM-DD');
      if(this.range?.end) payload.to = moment(this.range?.end).format('YYYY-MM-DD');
      this.searchDiscountsBydate(payload);
    },
    asyncFind(query) {
      this.typing = 'You are typing';
      if (query.length < 3) {
        clearTimeout(this.debounce);
        this.typing = false;
        return;
      }
      this.isLoadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.currentPage = 1;
        let payload = {
          page: 1,
          limit: this.perPage,
        };
        if(this.filter) payload.search = Utils.encodeUrlWithBase64(this.filter);
        if(this.discountStatus) payload.discountStatus = this.discountStatus;
        if(this.discountType) payload.discountType = this.discountType;
        if(this.range?.start) payload.from = moment(this.range?.start).format('YYYY-MM-DD');
        if(this.range?.end) payload.to = moment(this.range?.end).format('YYYY-MM-DD');
        this.searchDiscountsBydate(payload);
        this.typing = false;
      }, 1000);
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    asyncClearFind() {
      this.range = null;
      this.discountStatus = null;
      this.discountType = null;
      this.filter = null;
      this.actionGetDiscounts();
    },
    searchByValidTo() {
      this.currentPage = 1;
      let payload = {
        page: 1,
        limit: this.perPage,
      };
     
      if(this.filter) payload.search = Utils.encodeUrlWithBase64(this.filter);
      if(this.discountStatus) payload.discountStatus = this.discountStatus;
      if(this.discountType) payload.discountType = this.discountType;
      if(this.range?.start) payload.from = moment(this.range?.start).format('YYYY-MM-DD');
      if(this.range?.end) payload.to = moment(this.range?.end).format('YYYY-MM-DD');
      if (this.start_date) payload.from = moment(this.start_date).format('YYYY-MM-DD');
      if (this.end_date) payload.to = moment(this.end_date).format('YYYY-MM-DD');


      if (this.start_date && this.end_date) {
        const startDate = moment(this.start_date);
        const endDate = moment(this.end_date);

        if (startDate.isAfter(endDate)) {
          alert("Tanggal mulai tidak boleh lebih besar dari tanggal akhir!");
          return;
        }
      }
      this.searchDiscountsBydate(payload);
    },
    Reactivate(payload) {
      this.reactiveDiscount(payload)
        .then((res) => {
          if (res.status == 201) {
            this.$swal('Success', 'Status successfully updated', 'success');
          } else {
            this.$swal('Failed', 'Update status failed', 'error');
          }
        })
        .catch((err) => {
          console.log(err);
          this.$swal('Failed', 'Ops. Something went wrong', 'error');
        })
        .finally(() => this.actionGetDiscounts());
    },

    Deactivate(payload) {
      this.reactiveDiscount(payload)
        .then((res) => {
          if (res.status == 201) {
            this.$swal('Success', 'Status successfully updated', 'success');
          } else {
            this.$swal('Failed', 'Update status failed', 'error');
          }
        })
        .catch((err) => {
          console.log(err);
          this.$swal('Failed', 'Ops. Something went wrong', 'error');
        })
        .finally(() => this.actionGetDiscounts());
    },
    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>

<style scoped>
.discount {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
input::placeholder {
  font-style: normal;
  font-size: 14px;
}
.w-custom {
  width: 750px;
}

</style>
